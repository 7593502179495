
import Base from '@/Base';
import { Watch, Ref } from 'vue-property-decorator';

enum Mode { Login = 0, Edit, Start, Guessing, Done }

export default class Words extends Base {
    @Ref() readonly original!: HTMLInputElement;
    @Ref() readonly loginfield!: HTMLInputElement;

    private loginName = '';
    private user = '';
    private words: Word[] = [];
    private mode: Mode = Mode.Start;
    private addOriginal = '';
    private addTranslation = '';
    private currentWordIx = 0;
    private showAnswer = false;
    private answer = '';
    private answerClass = '';
    
    private setAnswerClass(): void {
        this.answerClass = this.answer === this.words[this.currentWordIx].Translation ? 'good' : 'wrong'; 
    }

    private get showCheckMark(): boolean {
        return this.showAnswer && this.words[this.currentWordIx].Translation.toLowerCase() === this.answer.trim().toLowerCase();
    }

    private get date(): string {
        return this.firstAvailableWord.CheckAgainAfter.toLocaleDateString();
    }
    
    private get time(): string {
        return this.firstAvailableWord.CheckAgainAfter.toLocaleTimeString();
    }

    private get firstAvailableWord(): Word {
        if (this.words.length === 0) {
            return new Word();
        }

        return this.words.reduce((prev, current) => (prev.CheckAgainAfter > current.CheckAgainAfter) ? prev : current);
    }

    private get nonInverted(): Word[] {
        return this.words.filter(w => !w.IsReverse);
    }

    private get loggedIn(): boolean {
        return this.user.length > 0;
    }

    public mounted() {
        this.user = '';
        (this.$refs.loginfield as HTMLInputElement).focus();
    }

    @Watch('mode', { immediate: true })
    public async onQueryIdChanged(mode: Mode) {
        if (mode === Mode.Guessing) {
            this.setWord();
        }
    }

    private doShowAnswer() {
        this.showAnswer = true; 
        this.setAnswerClass();
    }

    private sanitizeInput($e: KeyboardEvent) {
        if (!(/[a-z]/).test($e.key)) {
            $e.preventDefault();
        }
    }

    private login() {
        if (this.loginName.trim() === '') {
            return;
        }
        this.user = this.loginName;
        this.loginName = '';
        this.loadWords();
        if (this.words.length === 0) {
            this.mode = Mode.Edit;
        }
    }

    private rate(rated: string) {
        this.answer = '';
        this.showAnswer = false;
        this.answerClass = '';
        const curWord = this.words[this.currentWordIx];
        curWord.TestCount++;
        let now = new Date();
        switch(rated) {
            case 'difficult': 
                now.setSeconds(now.getSeconds() + 120);
                curWord.CheckAgainAfter = now;
                curWord.LastGuess = Guess.Difficult;
                break;
            case 'good':
                now.setDate(now.getDate() + 1);
                curWord.CheckAgainAfter = now;
                curWord.LastGuess = Guess.Good;
                break;
            case 'wrong':
            default:
                curWord.CheckAgainAfter = now;
                curWord.LastGuess = Guess.Wrong;
                break;
        }
        this.saveWords();
        this.setWord();
        (this.$refs.answerfield as HTMLInputElement).focus();
    }

    private setWord() {
        const now = new Date();
        const available = this.words.filter(w => new Date(w.CheckAgainAfter).getTime() < now.getTime());
        if (available.length === 0) {
            this.mode = 4;
            return;
        }

        let curOriginal = this.words[this.currentWordIx]?.Original;
        let ix = 0;
        do {
            ix = Math.floor(Math.random() * available.length);
        } while (curOriginal === available[ix].Original && available.length > 1);
        let newIx = this.words.findIndex(w => w.Original === available[ix].Original);
        this.currentWordIx = newIx;
    }

    private addWord() {
        if (!this.addOriginal?.trim() || !this.addTranslation?.trim()) {
            return;
        }
        const newWord = this.createWord();
        this.words.push(newWord);
        if (this.addOriginal.toLowerCase().trim() !== this.addTranslation.toLowerCase().trim()) {
            const invertedWord = this.createWord(true);
            this.words.push(invertedWord);
        }
        this.saveWords();
        this.addOriginal = '';
        this.addTranslation = '';
        (this.$refs.original as HTMLInputElement).focus();
    }

    private createWord(reverse = false): Word {
        const newWord = new Word();
        newWord.Original = reverse ? this.addTranslation : this.addOriginal;
        newWord.Translation = reverse ? this.addOriginal : this.addTranslation;
        newWord.IsReverse = reverse;
        return newWord;
    }

    private loadWords() {
        let words = localStorage.getItem(this.user + '_school_words');
        if (words !== null) {
            this.words = JSON.parse(words);
        }
    }

    private remove(word: Word) {
        let ix = this.words.findIndex(w => w.Original === word.Original && !word.IsReverse);
        if (ix > -1) {
            this.words.splice(ix, 1);
        }
        ix = this.words.findIndex(w => w.Translation === word.Original && word.IsReverse);
        if (ix > -1) {
            this.words.splice(ix, 1);
        }
        this.saveWords();
    }

    private deleteAll() {
        if (confirm(this.T('confirm_delete_all_words'))) {
            this.words = [];
            this.saveWords();
        }
    }

    private saveWords() {
        localStorage.setItem(this.user + '_school_words', JSON.stringify(this.words));
    }

    private addUnit(unit: string): void {
        switch(unit) {
            case '2_4':
                return this.addWords(this.unit2_4);
            default: 
                console.log('unit/lesson not found', unit);
                break;
        }
    }

    private addWords(unit: any[]) {
        if (this.words.length > 0) {
            this.deleteAll();
        }
        unit.forEach(el => {
            this.addOriginal = el.Original;
            this.addTranslation = el.Translation;
            this.addWord();
        });
    }

    private readonly unit2_4 = [ 
        {Original: 'pond', Translation: 'pound'}, 
        {Original: 'bankbiljetten', Translation: 'banknotes'}, 
        {Original: 'broccoli', Translation: 'broccoli'}, 
        {Original: 'wortelen', Translation: 'carrots'}, 
        {Original: 'wisselgeld', Translation: 'change'}, 
        {Original: 'goedkoop', Translation: 'cheap'}, 
        {Original: 'kassa', Translation: 'check out'}, 
        {Original: 'muntgeld', Translation: 'coins'}, 
        {Original: 'heerlijk', Translation: 'delicious'}, 
        {Original: 'drankje', Translation: 'drink'},
        {Original: 'vis', Translation: 'fish'},
        {Original: 'voedsel', Translation: 'food'},
        {Original: 'fruit', Translation: 'fruit'},
        {Original: 'fruit afdeling', Translation: 'fruit section'},
        {Original: 'groenteboer', Translation: 'greengrocer'},
        {Original: 'hongerig', Translation: 'hungry'},
        {Original: 'vlees', Translation: 'meat'},
        {Original: 'geld', Translation: 'money'},
        {Original: 'perziken', Translation: 'peaches'},
        {Original: 'pence', Translation: 'pence'},
        {Original: 'prijs', Translation: 'price'},
        {Original: 'boodschappenlijst', Translation: 'shopping list'},
        {Original: 'winkelwagen', Translation: 'shopping trolley'},
        {Original: 'aardbeien', Translation: 'strawberries'},
        {Original: 'supermarkt', Translation: 'supermarket'},
        {Original: 'snoep', Translation: 'sweets'},
        {Original: 'kopen', Translation: 'to buy'},
        {Original: 'betalen', Translation: 'to pay'},
        {Original: 'ruiken', Translation: 'to smell'},
        {Original: 'groenten', Translation: 'vegetables'}
    ];
}

enum Guess { Wrong, Difficult, Good }

class Word {
    public Original = '';
    public Translation = '';
    public LastGuess = Guess.Wrong;
    public CheckAgainAfter = new Date();
    public TestCount = 0;
    public IsReverse = false;
}
